/**
 * Load GA4 functions
 * */

(function (wpm, $, undefined) {

	wpm.getGA4OrderItems = function () {

		// "item_id"      : "34",
		// "item_name"    : "Hoodie",
		// "quantity"     : 1,
		// "item_brand"   : "",
		// "item_variant" : "Color: blue | Logo: yes",
		// "price"        : 45,
		// "currency"     : "CHF",
		// "item_category": "Hoodies"


		let orderItems = [];

		Object.values(wpmDataLayer.order.items).forEach((item) => {
			let orderItem;

			orderItem = {
				quantity     : item.quantity,
				price        : item.price,
				item_name    : item.name,
				currency     : wpmDataLayer.order.currency,
				item_category: wpmDataLayer.products[item.id].category.join("/"),
			};

			// Check if the item has custom parameters
			if (item?.custom_parameters) {
				// Iterate through each key-value pair in custom parameters
				Object.keys(item.custom_parameters).forEach((key) => {
					// Add each custom parameter to the orderItem object
					orderItem[key] = item.custom_parameters[key];
				});
			}

			if (0 !== item.variation_id) {

				orderItem.item_id      = String(wpmDataLayer.products[item.variation_id].dyn_r_ids[wpmDataLayer.pixels.google.analytics.id_type]);
				orderItem.item_variant = wpmDataLayer.products[item.variation_id].variant_description;
				orderItem.item_brand   = wpmDataLayer.products[item.variation_id].brand;
			} else {

				orderItem.item_id    = String(wpmDataLayer.products[item.id].dyn_r_ids[wpmDataLayer.pixels.google.analytics.id_type]);
				orderItem.item_brand = wpmDataLayer.products[item.id].brand;
			}

			orderItems.push(orderItem);
		});

		return orderItems;
	};

	wpm.ga4AddFormattedCategories = (item_data, categories) => {

		let maxCategories = 5;

		// remove categories with equal names from array
		categories = Array.from(new Set(categories));

		if (Array.isArray(categories) && categories.length) {

			item_data["item_category"] = categories[0];

			let max = categories.length > maxCategories ? maxCategories : categories.length;

			for (let i = 1; i < max; i++) {
				item_data["item_category" + (i + 1)] = categories[i];
			}
		}

		return item_data;
	};

	wpm.getCartItemsGa4 = () => {

		let data = [];

		Object.values(wpmDataLayer.cart).forEach(product => {
			data.push(wpm.ga4GetFullProductItemData(product));
		});

		return data;
	};

	wpm.ga4GetBasicProductItemData = (product) => {

		return {
			item_id  : product.dyn_r_ids[wpmDataLayer.pixels.google.analytics.id_type],
			item_name: product.name,
			// coupon   : "",
			// discount: 0,
			// affiliation: "",
			item_brand  : product.brand,
			item_variant: product.variant,
			price       : product.price,
			currency    : wpmDataLayer.shop.currency,
			quantity    : product.quantity,
		};
	};

	wpm.ga4AddListNameToProduct = (item_data, productPosition = null) => {

		item_data.item_list_name = wpmDataLayer.shop.list_name;
		item_data.item_list_id   = wpmDataLayer.shop.list_id;

		if (productPosition) {
			item_data.index = productPosition;
		}

		return item_data;
	};

	wpm.ga4GetFullProductItemData = (product) => {

		let item_data;

		item_data = wpm.ga4GetBasicProductItemData(product);
		item_data = wpm.ga4AddListNameToProduct(item_data, product.position);
		item_data = wpm.ga4AddFormattedCategories(item_data, product.category);

		return item_data;
	};

}(window.wpm = window.wpm || {}, jQuery));
