/**
 * Cookiefirst CMP
 * https://cookiefirst.com
 * https://cookiefirst.com/wordpress-cookie-consent-plugin/
 * https://support.cookiefirst.com/hc/de/articles/360011568738-Javascript-API
 */
export const cookiefirst = {

	/**
	 * Get the Cookiefirst cookie.
	 *
	 * @returns {{statistics: boolean, marketing: boolean, preferences: boolean, necessary: boolean}|null}
	 */
	getConsent: () => {

		// let consent = "{"necessary":true,"performance":true,"functional":true,"advertising":true,"timestamp":1731043717,"type":"category","version":"73d03af8-0302-48f4-8d17-802e03d4959b"}"
		let consent = wpm.getCookie("cookiefirst-consent")

		if (consent) {

			pmw.console.log("Cookiefirst CMP consent detected")

			if (isValidJson(consent)) {
				consent = JSON.parse(consent)
			} else {
				consent = decodeURI(consent)
				consent = consent.replace(/'/g, "\"") // Replace single quotes with double quotes as JSON requires double quotes
				consent = decodeURIComponent(consent)
				consent = consent.replace(/(\w+):/g, "\"$1\":") // Add double quotes to keys
				consent = JSON.parse(consent)
			}

			console.log("cookiefirst consent object", consent)

			return {
				statistics : consent.performance ?? true,
				marketing  : consent.advertising ?? true,
				preferences: consent.functional ?? true,
				necessary  : consent.necessary ?? true,
			}
		}

		return null
	},

	/**
	 * Cookiefirst
	 *
	 * 	If visitor accepts cookies in Cookiefirst unblock the scripts
	 */
	loadEventListeners: () => {

		window.addEventListener("cf_consent", function(event) {
			let data = {
				statistics : consent.performance ?? true,
				marketing  : consent.advertising ?? true,
				preferences: consent.functional ?? true,
				necessary  : consent.necessary ?? true,
			}

			pmw.consent.api.updateSelectively(data)
		}, true);
	},
}

const isValidJson = (str) => {
	try {
		JSON.parse(str)
	} catch (e) {
		return false
	}
	return true
}


